import './Card.css';
import RightArrowIcon from '../../Common/Icons/RightArrowIcon'

const Card = (props) =>{
    return(
        <div className="card">
            <div className="card-flex">
                <div className="img">
                    <img src={props.img} alt={props.alt} />
                </div>
                <div className="card-text-container">
                    <h1>{props.title}</h1>
                    <p>{props.text}</p>
                    <a href="/">{props.anchor} <RightArrowIcon /></a>
                </div>
            </div>
        </div>
    )
}

export default Card;