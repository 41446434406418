import './Snow.css'

const Snow = () =>{
    return(

        <div className="snowflakes" aria-hidden="true">
            <div className="snowflake">
                ❅
            </div>
            <div className="snowflake">
                ❅
            </div>
            <div className="snowflake">
                ❆
            </div>
            <div className="snowflake">
                ❄
            </div>
            <div className="snowflake">
                ❅
            </div>
            <div className="snowflake">
                ❆
            </div>
            <div className="snowflake">
                ❄
            </div>
            <div className="snowflake">
                ❅
            </div>
            <div className="snowflake">
                ❆
            </div>
            <div className="snowflake">
                ❄
            </div>
        </div>
    )
}

export default Snow