import './IconButton.css'

const IconButton = (props) =>{
    
    return(
        <a 
            href={props.href} 
            style={{backgroundColor:props.background, color:props.color, borderColor:props.borderColor}}
            className="btn-icon">
            {props.icon}
            <span>{props.anchortext}</span>
        </a>
    )
}

export default IconButton;