
import Navbar from '../../Components/Common/Navbar/Navbar';
import Hero from '../../Components/User/Hero/Hero.js';
import InfoSectionOne from '../../Components/User/InfoSectionOne/InfoSectionOne';
import InfoSectionTwo from '../../Components/User/InfoSectionTwo/InfoSectionTwo';
import InfoSectionThree from '../../Components/User/InfoSectionThree/InfoSectionThree';
import InfoSectionFour from '../../Components/User/InfoSectionFour/InfoSectionFour';
import Footer from '../../Components/Common/Footer/Footer';

const UserLanding = () =>{
    return(
        <>
            <Navbar />
            <Hero/>
            <InfoSectionOne />
            <InfoSectionTwo />
            <InfoSectionThree />
            <InfoSectionFour />
            <Footer />
        </>
    );
}

export default UserLanding