import './Footer.css'
import Logo from '../../../assets/img/png/1x/logo.png'
import FacebookIcon from '../Icons/FacebookIcon'
import TwitterIcon from '../Icons/TwitterIcon'

const Footer = () =>{
    return(
        <footer>
            <div className="container">
                <div className="footer-flex">
                    <div className="logo">
                        <img src={Logo} alt="SnowMiner™" />
                    </div>
                    <div className="footer-link-container">
                        <ul>
                            <li><a href="/">Schedule</a></li>
                            <li><a href="/">Drive</a></li>
                            <li><a href="/">Contact</a></li>
                        </ul>
                    </div>
                </div>
                <div className="divider"></div>
                <div className="footer-flex">
                    <div className="copyright">
                       <p> &copy; 2017 SnowMiner. All rights reserved</p>
                    </div>
                    <div className="social">
                        <ul>
                            <li><a href="https://twitter.com/"><TwitterIcon /></a></li>
                            <li><a href="https://facebook.com/"><FacebookIcon /></a></li>
                        </ul>
                    </div>
                </div>
            </div>
        </footer>
    )
}
export default Footer;