import { BrowserRouter as Router, Switch, Route } from "react-router-dom";

import UserLanding from '../Pages/UserLanding/UserLanding';
import DriverLanding from '../Pages/DriverLanding/DriverLanding';

const AppRoutes = () => {
	return (
		<Router>
			<Switch>
				<Route path="/" exact component={UserLanding} />
				<Route path="/driver" exact component={DriverLanding} />
			</Switch>
		</Router>
	);
}

export default AppRoutes