import './Hero.css';
import Snow from '../../Common/Snow/Snow'

const Header = () =>{
    return(
        <header className="hero">
            <div className="container">
                <div className="hero-text-container">
                    <Snow />
                    <h1>Snow removal with a simple tap</h1>
                    <p>Expert snowplow Miners to your driveway or sidewalk in minutes. 
                    Download the app and start your order!</p>

                    <div className="app-download-container">
                        <a href="https://www.apple.com/app-store" className="app-store" target="_blank" rel="noreferrer">appstore</a>
                        <a href="https://play.google.com/store/apps" className="play-store" target="_blank" rel="noreferrer">playstore</a>
                    </div>
                </div>
            </div>
        </header>
    )
}

export default Header;