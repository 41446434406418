import './InfoSectionFour.css';

import Card from '../../Common/Card/Card';
import PlayIcon from '../../Common/Icons/PlayIcon';
import IconButton from '../../Common/Buttons/IconButton/IconButton';

import GraphicGroup from '../../../assets/img/png/1x/graphic-group.png';
import AppIcon from '../../../assets/img/png/1x/app-icon.png';
import Home from '../../../assets/img/png/1x/icon-home.png';
import Truck from '../../../assets/img/png/1x/icon-truck.png';


const InfoSectionOne = () =>{
    return(
        <section className="info-section-four">
            <div className="container">
                <div className="content-flex">
                    <div className="img">
                        <img src={GraphicGroup} alt="Beautiful Interface" />
                    </div>
                    <div className="section-text-container">
                        <img src={AppIcon} alt="Snowminer" />
                        <h2>Easy and fun to use</h2>
                        <h1>Beautiful Interface</h1>
                        <p>If you’re in the Windows version, you have another option as well, which is the lorem function. If you type that command, you will insert random text beginning with lorem ipsum–this text is made up of random Latin words rather than the .</p>
                        <IconButton
                            href="/"
                            icon={<PlayIcon fill="#00A5E4" />}
                            anchortext="Watch video"
                            background="#ffff"
                            color="#00A5E4"
                            borderColor="#00A5E4"
                        />
                    </div>
                </div>

                <div className="content-flex">
                    <div className="cards-section">
                        <Card 
                            img={Home}
                            title="Order with SnowMiner"
                            text="Schedule a plow to clear your driveway or sidewalk so you don't need to freeze."
                            anchor="Order Now"
                        />
                        <Card 
                            img={Truck}
                            title="Drive with SnowMiner"
                            text="Schedule a plow to clear your driveway or sidewalk so you don't need to freeze."
                            anchor="Become a Driver"
                        />
                    </div>
                </div>
            </div>
        </section>
    )
}

export default InfoSectionOne;