import './App.css'
import AppRoutes from "./Routes/Routes";

export default function App() {
	return (
		<AppRoutes />
	);
}


