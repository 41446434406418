const FacebookIcon = () =>{
    return(
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="8"
            height="16"
            viewBox="0 0 8 16"
        >
            <g
                fill="none"
                fillRule="evenodd"
                stroke="none"
                strokeWidth="1"
                opacity="0.5"
            >
                <path
                    fill="#FFF"
                    fillRule="nonzero"
                    d="M5.512 15.738V9.762h2.25V6.738h-2.25V4.875c0-.281.064-.54.193-.773.129-.235.31-.352.545-.352h1.512V.762h-2.25c-.844 0-1.559.293-2.145.879a2.877 2.877 0 00-.879 2.109v2.988H.238v3.024h2.25v5.976h3.024z"
                ></path>
            </g>
      </svg>
    )
}

export default FacebookIcon