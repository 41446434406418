const TwitterIcon = () =>{
    return(
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="13"
            viewBox="0 0 16 13"
        >
            <g
                fill="none"
                fillRule="evenodd"
                stroke="none"
                strokeWidth="1"
                opacity="0.5"
            >
                <path
                    fill="#FFF"
                    fillRule="nonzero"
                    d="M5.082 13c1.875 0 3.54-.48 4.992-1.441 1.336-.844 2.38-1.993 3.13-3.446.702-1.383 1.054-2.8 1.054-4.254l-.035-.421A6.175 6.175 0 0015.84 1.75a6.604 6.604 0 01-1.828.527A3.256 3.256 0 0015.418.484a6.329 6.329 0 01-2.04.774c-.632-.68-1.429-1.02-2.39-1.02-.867 0-1.617.317-2.25.95a3.115 3.115 0 00-.949 2.285c0 .234.035.48.106.738a9.068 9.068 0 01-3.71-1.002A9.075 9.075 0 011.25.836a3.17 3.17 0 00-.422 1.6c0 .574.129 1.095.387 1.564a3.15 3.15 0 001.02 1.125c-.493 0-.973-.129-1.442-.387v.035c0 .774.246 1.454.738 2.04.492.585 1.102.96 1.828 1.125a3.2 3.2 0 01-.843.105 2.76 2.76 0 01-.598-.07c.21.656.592 1.19 1.143 1.6.55.41 1.166.626 1.845.65-1.148.914-2.472 1.37-3.972 1.37-.258 0-.516-.011-.774-.034C1.66 12.519 3.3 13 5.082 13z"
                ></path>
            </g>
        </svg>
    )
}

export default TwitterIcon