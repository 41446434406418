import './InfoSectionOne.css';
import ConvenienceImg from '../../../assets/img/png/1x/convenience.png';

const InfoSectionOne = () =>{
    return(
        <section className="info-section-one">
            <div className="container">
                <div className="content-flex">
                    <div className="img">
                        <img src={ConvenienceImg} alt="Convenience is priority" />
                    </div>
                    <div className="section-text-container">
                        <h2>Time for what you want</h2>
                        <h1>Convenience is priority</h1>
                        <p>If you’re in the Windows version, you have another option as well, which is the lorem function. If you type that command, you will insert random text beginning with lorem ipsum–this text is made up of random Latin words rather than the .</p>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default InfoSectionOne;