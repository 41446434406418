

import './Navbar.css'

import Logo from '../../../assets/img/png/1x/logo.png'
import CTAButton from '../Buttons/CTAButton/CTAButton'

const Navbar = () =>{
    

    return(
        <nav className="nav">
            <div className="container">
                <input type="checkbox" id="nav-check" />
                <div className="nav-header">
                    <a href="/">
                        <div className="nav-title">
                            <img src={Logo} alt="SnowMiner™" />
                        </div>
                    </a>
                </div>
                <div className="nav-btn">
                    <label htmlFor="nav-check">
                        <span></span>
                        <span></span>
                        <span></span>
                    </label>
                </div>
                
                <div className="nav-links">
                    <a href="/">Schedule</a>
                    <a href="/">Drive</a>
                    <a href="/">Help</a>
                    <CTAButton
                        href="/"
                        anchortext="Contact us" 
                        background="#ffff"
                        color="#2B81DA"
                    />
                </div>
            </div>
        </nav>
    )
}

export default Navbar;