import './InfoSectionThree.css';
import CTAButton from '../../Common/Buttons/CTAButton/CTAButton';

const InfoSectionTwo = () =>{
    return(
        <section className="info-section-three">
            <div className="container">
                <h1 className="heading">How SnowMiner Work</h1>
                <div className="content-flex">
                    <div className="item">
                        <div className="item-number">1</div>
                        <div className="divider"></div>
                        <div className="text-content">
                            <h1>Schedule a snowplow</h1>
                            <p>We if you’re in the Windows version, you have another option as well, which is good.</p>
                        </div>
                    </div>
                    <div className="item">
                        <div className="item-number">2</div>
                        <div className="divider"></div>
                        <div className="text-content">
                            <h1>Snow removal</h1>
                            <p>We if you’re in the Windows version, you have another option as well, which is the lorem function. If you’re in the</p>
                        </div>
                    </div>
                    <div className="item">
                        <div className="item-number">3</div>
                        <div className="divider"></div>
                        <div className="text-content">
                            <h1>Leave a review</h1>
                            <p>We if you’re in the Windows version, you have another option as well, which is the lorem function which is good as well.</p>
                        </div>
                    </div>
                </div>
                <CTAButton
                    href="/"
                    anchortext="Start an order"
                    background="#02a5e3"
                    color="#ffff"
                />
            </div>
        </section>
    )
}

export default InfoSectionTwo;