import './InfoSectionTwo.css';
import SnowflakeImage from '../../../assets/img/png/1x/snowflake.png';
import OnTheGoImage from '../../../assets/img/png/1x/on-the-go.png';
import PricingImage from '../../../assets/img/png/1x/pricing.png';

const InfoSectionTwo = () =>{
    return(
        <section className="info-section-two">
            <div className="container">
                <div className="content-flex">
                    <div className="item">
                        <img src={SnowflakeImage} alt="Snowflake" />
                        <h1>No longer brave the cold</h1>
                        <p>We if you’re in the Windows version, you have another option as well, which is good.</p>
                    </div>
                    <div className="item">
                        <img src={OnTheGoImage} alt="On the go" />
                        <h1>Order on the go</h1>
                        <p>We if you’re in the Windows version, you have another option as well, which is the lorem function. If you’re in the</p>
                    </div>
                    <div className="item">
                        <img src={PricingImage} alt="On the go" />
                        <h1>Simple process, simple pricing</h1>
                        <p>We if you’re in the Windows version, you have another option as well, which is the lorem function which is good as well.</p>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default InfoSectionTwo;