import './CTAButton.css'

const CTAButton = (props) =>{

    return(
        <a 
            href={props.href} 
            style={{backgroundColor: props.background, color:props.color}}
            className="btn-cta">
                {props.anchortext}
        </a>
    )
}

export default CTAButton;